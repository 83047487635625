import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import FormContact from "../components/organisms/FormContact"




const GetAQuotePage = () => (
  <Layout>
    <SEO title="Get a Quote" />
    <FormContact />
  </Layout>
)

export default GetAQuotePage;
